import { DateTime } from 'luxon';

// Extender las interfaces de luxon para incluir el métodos personalizados
declare module 'luxon' {
    interface DateTime {
        /**
         * Transformar DateTime a objeto JS Date "en bruto", sin transformaciones de zona horaria.
         */
        toRawJSDate(): Date;
    }
}

/////////////////////
////// Metodos

DateTime.prototype.toRawJSDate = function (): Date {
    return this.setZone(DateTime.local().zoneName || '', { keepLocalTime: true }).toJSDate();
};